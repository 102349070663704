import $ from 'jquery';

class Header {
  constructor() {
    this.init();
    this.onClick();
  }

  init() {
    $(document).ready(this.handleScroll);
    $(window).scroll(this.handleScroll);
  }

  handleScroll() {
    const $header   = $('.common-header');
    const offsetTop = $header.offset().top;
    const top       = 'isTop';

    cancelAnimationFrame(frameId);
    let frameId = requestAnimationFrame(() => {
      if (offsetTop < 10 && !$header.hasClass(top)) {
        $header.addClass(top);
      } else if (offsetTop >= 10 && $header.hasClass(top)) {
        $header.removeClass(top);
      }
    });
  }
  onClick() {
    const $header   = $('.common-header');
    const $trigger = $('.common-header__trigger');
    // const $nav = $('.common-header__navItems');
    const opened   = 'isOpened';

    $trigger.on('click', () => {
      $header.toggleClass(opened);
    })
  }
}

export default Header;
