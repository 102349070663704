import $ from 'jquery';
import Header from './header';
import Slick from './slick';
import Scroll from './scroll';
import Particles from './particles';
import ChangeLang from './lang';


$(() => {
  new Header();
  new Scroll();
  new Slick('.top-kv__items');
  new Particles();
  new ChangeLang();
});
