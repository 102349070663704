import $ from 'jquery';

class Scroll {
  constructor() {
    this.init();
  }
  init() {
    // Animate to section when nav is clicked
    $('a[href^="#"]').click(function(){
      var speed = 500;
      var href= $(this).attr('href');
      var target = $(href == '#' || href == "" ? 'html' : href);
      var position = target.offset().top;
      $('html, body').animate({scrollTop:position}, speed, 'swing');
      return false;
    });
  }
}

export default Scroll;
