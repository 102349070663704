import $ from 'jquery';
import 'slick-carousel';

class Slick {
  constructor(selector) {
    this.$el = $(selector);
    this.init();
  }
  init() {
    this.$el.slick({
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      arrows: false,
    });
  }
}

export default Slick;
