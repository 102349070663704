import $ from 'jquery';

class ChangeLang {
  constructor() {
    this.changeClass();
    this.changeLang();
  }
  changeLang() {
    $('.change-lang').on('click', () => {
      const lang = sessionStorage.getItem('lang');
      $('html').removeClass('lang-en lang-ja');
      if (lang === 'en') {
        sessionStorage.setItem('lang', 'ja');
        $('html').addClass('lang-ja');
      } else {
        sessionStorage.setItem('lang', 'en');
        $('html').addClass('lang-en');
      }
      console.log(sessionStorage.lang);
    });
  }
  changeClass() {
    const lang = sessionStorage.getItem('lang');
    console.log(sessionStorage.lang);
    if (lang === 'en') {
      $('html').addClass('lang-en').removeClass('lang-ja');
    } else {
      $('html').removeClass('lang-en').addClass('lang-ja');
    }
  }
}

export default ChangeLang;
